import { Component, Vue, Provide } from 'vue-property-decorator';
import { Menu } from "@/interface/pagas/mine";
@Component({
  components: {
  },
})

export default class Common extends Vue {
  @Provide() list:Array<Menu> = [
    {id:1,name:"我的帐号",link:"account",iPath:"/Account",isToken:true},
    {id:2,name:"我的定单",link:"order",iPath:"/Order",isToken:true},
    {id:3,name:"我的收藏",link:"collect",iPath:"/Collect",isToken:true},
    {id:4,name:"我的地址",link:"address",iPath:"/Address",isToken:true},
    {id:5,name:"我的优惠卡",link:"coupon",iPath:"/Coupon",isToken:true},
    {id:7,name:"消息通知",link:"notice",iPath:"/Notice",isToken:true},
    {id:6,name:"反馈意见",link:"opinion",iPath:"/Opinion",isToken:false},
    {id:8,name:"帮助中心",link:"help",iPath:"/Help",isToken:false},
    {id:9,name:"隐私保护",link:"privacy",iPath:"/Privacy",isToken:false},
  ];
  @Provide() iPath:string = ""


  created() {
    const _this:any = this;

    _this.iPath = _this.$route.path
  }


  /**
   * @Author HS
   * @Date 2021/6/29 2:19 下午
   * @Description: Index页面跳转
   * @Params: null
   * @Return: null
   */
  goHome(){
    const _this:any = this;
    _this.$base.goRouter(_this.$IndexConfig.home.path)
  }

  /**
   * @Author HS
   * @Date 2021/7/9 3:56 下午
   * @Description: Mine页面跳转
   * @Params: { number ： index - true } [下标]
   * @Return: null
  */
  goMine(index:number){
    const _this:any = this;
    const path:string = _this.list[index].link
    const _falg = _this.list[index].isToken
    _this.iPath = _this.list[index].iPath

    if(_this.$base.isLogin() && _falg){
      const link = _this.$base.routeLinkSplice(_this.$LoginConfig.login.path,path)
      _this.$base.goRouter(link)
      return
    }
    _this.$base.goRouter(_this.$MineConfig[path].path)

  }

}
